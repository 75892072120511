@media screen and (min-width: 1960px) {
  .layout-main,
  .landing-wrapper {
    width: 1504px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}


@media screen and (max-width: 1538px) {
  .p-button.p-button-sm {
    padding: 0.5rem 0 0.5rem 0.5rem;
  }
}

@media screen and (max-width: 1300px) {
  .layout-content {
    margin: 0 1rem;
  }
  .flex.btn-wrap {
    flex-direction: column;
  }
  .p-avatar.p-avatar-xl {
    width: 5rem;
    height: 5rem;
  }
  .text-2xl {
    font-size: 1rem!important;
  }
  .p-panel .p-inputgroup.flex-1.filter-wrap {
    flex-wrap: wrap;
    .p-chip {
      margin-top: 0.5rem;
    }
  }
}

@media screen and (max-width: 1024px) {
  .bread-crumb-main {
    background-image: url('../../images/banner-sm.jpg')!important;
  }
}

@media (min-width: 992px) {
  .layout-wrapper {
    &.layout-overlay {
      .layout-main-container {
        margin-left: 0;
      }

      .layout-sidebar {
        transform: translateX(-100%);
        left: 0;
        top: 0;
        height: 100vh;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &.layout-overlay-active {
        .layout-sidebar {
          transform: translateX(0);
        }
      }
    }

    &.layout-static {

      &.layout-static-inactive {
        .layout-sidebar {
          transform: translateX(-100%);
          left: 0;
        }

        .layout-main-container {
          margin-left: 0;
          // padding-left: 2rem;
        }
      }
    }

    .layout-mask {
      display: none;
    }
  }
}

@media (max-width: 991px) {
  .Address-form-container.layout-content .user-page {
    margin: 0;
    &> div {
      padding: 0.5rem!important;
    }
    .edit-space {
      margin: 0;
    }
    .password-tip-card {
      padding: 0;
    }
  }

  .text-4xl.text-900 {
    font-size: 1.4rem!important
  }
  .price.text-3xl {
    font-size: 1.2rem!important
  }
  .cust-dialog {
    width: 100%;
  }
  .p-galleria .p-galleria-item img {
    max-height: 300px;
  }
  .left-filter {
    .p-datatable-wrapper {
      max-height: 15rem;
    }
  }
  .hide-sm {
      display: none;
  }
  .blocked-scroll {
    overflow: hidden;
  }

  .layout-wrapper {
    .layout-main-container {
      margin-left: 0;
      // padding-left: 2rem;
    }

    .layout-sidebar {
      transform: translateX(-100%);
      left: 0;
      top: 0;
      height: 100vh;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .layout-mask {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 998;
      width: 100%;
      height: 100%;
      background-color: var(--maskbg);
    }

    &.layout-mobile-active {
      .layout-sidebar {
        transform: translateX(0);
      }

      .layout-mask {
        display: block;
        animation: fadein $transitionDuration;
      }
    }
  }
}
@media (max-width: 1024px) {
  .Address-form-container{
    // padding: 10px 10px !important;
    .user-page{
        padding: 0 0 !important;
    }
}

}

@media screen and (max-width: 845px) {
  .location-dialog {
    width: 100%!important; 
  }
  .bread-crumb-main {
    background-image: url('../../images/banner-xs.jpg')!important;
  }
  .custom-dialog.checkout-prod {
    .p-dialog-header {
      .p-dialog-title span {
        font-size: 0.8rem!important;
        font-weight: normal;
      }
    }
    .p-dialog-content {
      padding-top: 0.5rem!important;
    }
    .sm-view {
      display: flex;
      flex-direction: row;
      img {
          width: auto !important;
          margin: 0 0.5rem !important;
          max-height: 10rem;
      }
      .flex.flex-column.align-items-center {
        align-items: flex-start!important;
        font-size: 0.8rem;
      }
    }
    h4.text-green2 {
      margin: 0 0 0.2rem 0.5rem;
      font-size: 1.3rem;
    } 
    .field label {
      margin-bottom: 0.2rem;
      &>span {
        padding: 0!important;
      }
    }
    .p-dialog-footer {
      &> .flex.justify-content-end {
        justify-content: center!important
      }
      .p-button {
        padding: 0.5rem!important;
        .p-button-label {
          font-size: 0.8rem;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .img-none {
    display: none;
  }
      .product-list-detail h5 {
        font-size: 16px;
      }
    .checkout-btn{
      display: block;
      width: auto !important;
    }
  }
  
@media (max-width: 576px) {
  .edit-add-btns {
    .p-inputgroup {
      margin-bottom: 1rem;
    }
    .btn-wrap {
      display: flex;
      button {
        float: none!important;
        &:first-child {
          order: 2;
        }
      }
    }
  }
  div.submit-button {
    margin-top: 0.5rem!important;
    display: flex;
    flex-direction: inherit!important;
      button {
        margin-left: 0!important;
        float: none!important;
        &:first-child {
          margin-left: 1rem!important;
          order: 2;
        }
      }
  }
  .bread-crumb-main {
    background-image: url('../../images/banner-xxs.jpg')!important;
  }
  .pd-3 {
    padding: 0 8px;
  }
.mobile-txt-fnt {
  font-size: 20px;
}
  .card {
    padding: 0 !important;
  }
  // .product-list-quantity{
  //   margin-top: 13px;
  // }

  .orderlist-demo .product-item .product-category {
    font-size: 14px;
  }
  .product-badge {
    font-size: 10px !important;
  }
  // .Address-form-container {
  //   padding: 0 10px !important;
  // }
  .user-address-card {
    margin: 0 !important;
  }

.grid-table-mobile{
  .card{
    padding: 0 5px !important;
  }
}
}
