.order-top-color {
  background-color: $color-green;
  color: white;

  margin: 0;
  align-items: center;
  // height: 80px;
}
.product-category-icon {
  vertical-align: middle;
}

.order-items-img {
  width: 75px;
  // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border: 1px solid $color-border;
}
@media (max-width: 768px) {
  .order-top-color p {
    font-size: 14px;
  }
  .mobile-head {
    font-size: 16px;
  }
  .left-mobile {
    text-align: right;
  }
}
@media (max-width: 767px) {
  .mobile-flex {
    flex-wrap: nowrap;

    flex-direction: column;
  }
  .left-mobile {
    text-align: left !important;
  }
}
@media (max-width: 576px) {
  .left-mobile {
    text-align: left !important;
  }
  .order-summary-layout {
    .p-card{

        .p-card-body{
            padding: 0;
        }
    }
  }
}
.text-green {
  color: $color-light-green;
}
.order-item-list {
  .product-category {
    color: $color-grey-text;
  }
}
