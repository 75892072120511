@media screen and (max-width: 599px) {
  .input-container {
    margin-left: 25%;
    width: 60% !important;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  .dropdown-search {
    margin-left: 25% !important;
    width: 53% !important;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }


  .filter-button{
    margin-bottom: 0.5rem;
    width: 25% !important;
    margin-left: 2%;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
   }
   

   .buttons-container {
    display: flex;
    align-items: center;
    margin-left: 5%;
    width: 80%; 
  }

  .add-button-container {
    width: 50% !important;
    float: left !important;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 3%;

  }

}

@media (min-width: 600px) and (max-width: 1023px) {

  .dropdown-search{
      float: left !important;
      width: 20% !important;
      margin-left: 2%;
  }

  .filter-button{
    float: left !important;
    text-align: right !important;
    width: 10% !important;
    margin-left: 8%;
   }
  
   .add-button-container {
   text-align: right;
   margin-left: 3%;
  }

}



@media screen and (min-width: 1024px) {

  .dropdown-search{
      float: left !important;
      width: 20% !important;
      margin-left: 2%;
  }
 
  .filter-button{
  float: left !important;
  text-align: right !important;
  width: 10% !important;
  margin-left: 8%;
 }

 .add-button-container {
 text-align: right;
 margin-left: 3%;
}

}

/* create-invoice css */
@media (max-width: 768px) {
  .responsive-button {
    width: 100% !important;
    max-width: none !important;
    margin: 0 0 0.5rem 0 !important;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .responsive-button {
    width: 100% !important;
    max-width: none !important;
    margin: 0 0 0.5rem 0 !important;
  }
}

/* invoice-payment css */
@media (max-width: 768px) {
  .responsive-pay {
margin-top: 6% !important;
margin-bottom: 6% !important;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .responsive-pay {
    margin-top: 5% !important;
    margin-bottom: 5% !important;
      }
}

/* create-invoice-total css */
@media (max-width: 768px) {
  .responsive-font {
font-size: 0.6rem !important;
  }
  
}

@media (min-width: 769px) and (max-width: 992px) {
  .responsive-font {
    font-size: 0.7rem !important;
      }
}


/* select-payment-invoice css */
@media (max-width: 768px) {
  .responsive-amount {
    margin-left: 10px;
    margin-top: 5px;
       }
}

@media (min-width: 769px) and (max-width: 992px) {
  .responsive-amount {
   margin-left: 50px;
   margin-top: 5px;
      }
}
@media (min-width: 993px)  {
  .responsive-amount {
   margin-left: 80px;
   margin-top: 5px;
      }
}