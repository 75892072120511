* {
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    font-family: var(--font-family);
    color: var(--text-color);
    background-color: var(--surface-ground);
    margin: 0;
    padding: 0;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: var(--primary-color);
}

.layout-wrapper {
    min-height: 100vh;
    background: $color-white;
}
.prod-item {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $color-grey;
    margin-bottom: 0.5rem;
    p {
        margin: 0;
    }
}

