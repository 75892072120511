/* General */
$borderRadius:12px;             /* border radius of layout element e.g. card, sidebar */ 
$transitionDuration:.2s;        /* transition duration of layout elements e.g. sidebar, overlay menus */ 

/* Colors */
$color-black: #000;
$color-grey-text: #9c9c9c;
$color-white: #fff;
$color-grey: #dee2e6;
$color-grey2: #f5f5f5;
$color-grey3:#a1a7ac;
$color-grey4:#f8f9fa;
$color-blue: #2c8de7;
$color-blue-hover: #359af8;
$color-border: #cdcdcd;
$color-light-green:#009447;
$color-green:#007f3d;
$color-green2:#007d36;
$color-orange:#ff7a00;
