@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader-animated {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120px;
    height: 120px;
    margin: -60px 0 0 -60px;
    animation: spin 4s linear infinite;
    vertical-align: middle;
}

.loader-logo {
    position: absolute;
    left: 47.5%;
    vertical-align: middle;
}

.displaygird {
    display: grid !important;
    place-items: center !important;
}
.pause-loading {
    display: block;
    background: rgba(255, 255, 255, 0.678) no-repeat 0 0;
    width: 100%;
    height: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
}
