/* styles/CartIcon.css */
.cart-icon {
    position: relative;
    cursor: pointer;
  }
  
  .fa-shopping-cart {
    font-size: 24px;
  }
  
  .cart-count {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: #ff5722;
    color: white;
    border-radius: 50%;
    padding: 4px 8px;
    font-size: 12px !important;
    display: inline !important;
  }
  