.card-with-border {
  border: 1px solid #ccc; 
  border-radius: 5px;
  padding: 16px; 
  margin: 16px; 
}

.submit-button{
    margin-top: 20px !important;
    display: flex;
    flex-direction: row-reverse;
    button {
      margin-left: 1rem;
    }
}

.bold-divider {
    border-top:  solid #c7c1c1; 
  }
.password-tip-card{
  padding-bottom: 64px;
}
.change-password-header, .password-header{
  font-weight: bold; 
  border-bottom: 2px solid #00000091;
  font-size: 1rem; 
  padding-top: 13px;
  margin-left: 17px;
}

.formgrid.grid {
  flex-direction: column;
} 

@media (max-width: 600px) {
    /* Apply mobile styles here */
    .grid {
      flex-direction: column;
      align-items: center;
    }
    .col-9 {
      width: 100%;
    }
  }
  @media (max-width: 575px) {
    /* Apply mobile styles here */
    .grid-row {
      flex-direction: row;
    }
  }
