.card-with-border {
  border: 1px solid #ccc; /* Specify your border style here */
  border-radius: 5px; /* Optionally add some border radius for rounded corners */
  padding: 16px; /* Optionally add padding to the cards */
  margin: 16px; /* Optionally add margin to separate the cards */
}

.flex-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
}

.form-control-feedback{
  position: absolute;
  z-index: 2;
  display: block !important;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem !important;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  font-size: 15px !important;
}
/* .subdetails{
  margin-left: 23px !important;
  overflow: hidden !important;
} */
.subheader{
  margin-top:63px;
  margin-left:17px;
  overflow:hidden !important;
  gap: 1.5rem;
}
.Header{
  /* max-width: 100%; */
  width: 200px !important;
  margin-left: 94px;
}
.saveandcancel{
float: right !important;
}
.bold-text {
  font-weight: bold;
}

.underline-text {
  text-decoration: underline;
}
input[readonly] {
  background-color:#8888881f; 
  color: #888; 
  
}
.required-field {
  color: red; 
  margin-left: 4px; 
}

.dialog-header {
  font-weight: bold; 
  border-bottom: 2px solid #00000091;
  width: 153px;
  font-size: 16px; 

}
.edit-header, .edit-details {
  font-weight: bold; 
  border-bottom: 2px solid #00000091;
  font-size: 1.2rem; 
  padding-top: 12px;
  margin-left: 14px;
  display: inline-block;
}
.edit-space{
  margin-bottom: 183px;
  font-size: 1rem;
  padding: 4px;
}
.sub-header{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.new-address{
  padding-top: 5px;
}
.button-align{
  align-items: center;
}

.main-button{
  align-items: center;
}
.user-detail{
width: 74px;
height: 74px;
margin-left:80px ;
align-items: center;
}
.Change-picture{
  width:100%;
  justify-content: center;
}
.grid-user{
  padding-top: 125px;
}
.profile-wrap {
  .p-card-content {
    padding-top: 0;
  }
}
.field {
  margin-bottom: 0rem;
}
.sub-header{
  h4{
    text-transform: capitalize;
  }
}
#address{
  position: relative !important;
}
.mt-43{
  margin-top: -43px !important;
}