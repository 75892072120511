.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.product-badge.status-LOWSTOCK {
  background: #feedaf;
  color: #8a5340;
}

.product-badge.status-INSTOCK {
  background: #c8e6c9;
  color: #256029;
}

// .product-list-quantity {
//     width: 40px;
//     flex: auto;
// }

div[class="p-orderlist-controls"] {
  display: none !important;
}

.orderlist-demo .product-item {
  display: flex;
  align-items: center; /* Vertically align items */
  justify-content: space-between; /* Space out items evenly */
  flex-wrap: nowrap; /* Prevent wrapping */
  padding: 0.5rem;
  width: 100%;
  gap: 1rem; /* Add spacing between columns */
}

/* Product name container */
.orderlist-demo .product-item .product-name {
  flex: 2; /* Allow the product name to take a reasonable width */
  max-width: 300px; /* Limit the width of the product name */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add ellipsis for overflowed text */
}

.img-details {
  display: flex;
  justify-content: center;
  max-width: 291px;
  width: 100%;
}

.orderlist-demo .product-item img {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-right: 1rem;
  background-color: $color-white;
  width: 100%;
  max-height: 70px
}

.product-item {
  .image-container{
    margin-right: 20px;
  }
}
// .orderlist-demo .product-item .product-list-detail {
//     flex: 1 1 0;
// }

.orderlist-demo .product-item .product-list-action {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.orderlist-demo .product-item .product-category-icon {
  vertical-align: middle;
  margin-right: 0.5rem;
}

.orderlist-demo .product-item .product-category {
  vertical-align: middle;
  line-height: 1;
  color: $color-grey-text;
}

.item-list-card {
  ul.p-orderlist-list {
    border: 0 !important;
  }

  .p-orderlist-item {
    background: $color-grey2 !important;
    margin: 0 10px 10px 10px !important;
    padding: 0.5rem !important;
  }
}

@media screen and (max-width: 768px) {
  .product-list-detail {
    padding-left: 13px;
    padding-top: 2px;
    width: 200px;
   
  }

  .orderlist-demo .product-item {
    flex-wrap: wrap;
    
  }

  .product-list-quantity button.p-inputnumber-button {
    width: 30px;
    height: 30px;
  }

  .p-inputnumber-buttons-horizontal .p-inputnumber-input {
    height: 30px;
    text-align: center;
  }
}

@media screen and (max-width: 576px) {
  .orderlist-demo .product-item img {
    margin: 0 0 1rem 0;
    width: 100px;
  }

  .product-list-action {
    h5 {
      font-size: 1rem;
    }
  }
}
.product-wrapper {
  .quantity-border {
    .p-inputnumber-button {
      /* Set a visible background color specifically for the + and - buttons */
      background: #f0f0f0 !important; /* Light gray background for visibility */
      border-color: $color-grey-text; /* Keep original border color */
      border-style: solid;
      border-radius: 0;
      color: $color-grey-text; /* Original text color */

      &.p-inputnumber-button-up {
        border-width: 1px 1px 1px 0px;
      }

      &.p-inputnumber-button-down {
        border-width: 1px 0px 1px 1px;
      }

      /* Add hover styles for better interactivity */
      &:hover {
        background: #e0e0e0; /* Slightly darker background on hover */
        color: $color-black; /* Ensure text/icon is dark on hover */
      }
    }

    .p-inputnumber-input {
      background: transparent !important; /* Preserve original styling */
      border: 1px solid $color-grey-text !important; /* Preserve original border color */
      width: 3.357rem !important;
      font-weight: 700;
      font-size: 14px !important;
      color: $color-black; /* Ensure text remains visible */
    }

    .p-button-icon {
      filter: none; /* Ensure icons are not inverted */
      font-size: 10px;

      /* Optional hover styling for icons */
      &:hover {
        color: $color-black; /* Keep the icon visible on hover */
      }
    }
  }

  .product-list-button {
    .p-button-danger {
      border-radius: 0 !important; /* No color changes for the delete button */
    }
  }
}

.product-list {
  margin: 0;
  padding: 0;
}

.product-item {
  margin: 0; /* Removes external spacing */
  padding: 0; /* Removes internal spacing */
  line-height: 0.6; /* Adjusts spacing between lines */
  display: flex; /* Optional: Ensures alignment */
  justify-content: space-between; /* Aligns text nicely */
}

.shopping-btn {
  width: 240px !important;
  padding: 0.5rem !important;
  color: $color-grey-text;
  font-size: 1rem !important;
}
